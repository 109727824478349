import Grid from '@mui/material/Grid'
import { STORY_CARDS } from './constants'
import {
  StyledText,
  StyledContentSeparator,
  StyledBodyContainer,
} from './styles'
import StoryCard from './StoryCard'

export const StoryBlockV2 = () => {
  return (
    <StyledBodyContainer container alignItems="center" justifyContent="center">
      <Grid item xs={12} sm={12}>
        <StyledText variant="h2">How we set you up for success</StyledText>
      </Grid>
      <Grid
        container
        direction="row"
        spacing={{ xs: 4, sm: 6 }}
        justifyContent="center"
      >
        {STORY_CARDS.map((storyCard) => (
          <Grid key={storyCard.heading} item xs={12} sm={4}>
            <StoryCard
              heading={storyCard.heading}
              description={storyCard.description}
              ImageComponent={storyCard.ImageComponent}
            />
          </Grid>
        ))}
        <StyledContentSeparator item xs={12} />
      </Grid>
    </StyledBodyContainer>
  )
}

export default StoryBlockV2
