import CuratingJoy from './CuratingJoySvg'
import HelpingYouGrowSvg from './HelpingYouGrowSvg'
import ProvidingSupportSvg from './ProvidingSupportSvg'

export const STORY_CARDS = [
  {
    heading: 'Curating joy',
    description:
      'We keep our assortment relevant through trusted, invite-only selling partners so your items stand out.',
    ImageComponent: CuratingJoy,
  },
  {
    heading: 'Helping you grow',
    description:
      'We offer expert services that help boost your brand’s visibility, reach and buyability.',
    ImageComponent: HelpingYouGrowSvg,
  },
  {
    heading: 'Providing support',
    description:
      'We have a dedicated team to ensure you have a smooth launch, and options to assist your journey.',
    ImageComponent: ProvidingSupportSvg,
  },
]
