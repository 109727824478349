export const ENVIRONMENT_DEVELOPMENT = 'DEVELOPMENT'
export const ENVIRONMENT_STAGE = 'STAGE'
export const ENVIRONMENT_PRODUCTION = 'PRODUCTION'

export const STAGE_HOSTNAME = 'plus.perf.target.com'
export const PROD_HOSTNAME = 'plus.target.com'

const stageGateway = `https://stage-api.target.com`
const prodGateway = `https://api.target.com`

const firefly = '/consumers/v1/ingest/internal/internal_app'

const dev = {
  environment: ENVIRONMENT_DEVELOPMENT,
  apiGatewayKey: '99ff70ddf485e4eec5e1bb5fc3c1855d86161c25',
  firefly: `${stageGateway}${firefly}`,
}

const stage = {
  ...dev,
  environment: ENVIRONMENT_STAGE,
}

const prod = {
  environment: ENVIRONMENT_PRODUCTION,
  apiGatewayKey: 'f84694eac26c36659e61b1ae79c5ef52aa424b68',
  firefly: `${prodGateway}${firefly}`,
}

const envConfigs = { dev, stage, prod }

const hostname = window?.location?.hostname ?? ''

const isProd = hostname.match(new RegExp(`^${PROD_HOSTNAME}$`, 'i'))

const isStage = !isProd && hostname.match(/(stg|stage|perf|qa|dev)/i)

const appEnv =
  process.env.REACT_APP_ENV ||
  (isStage && 'stage') ||
  (isProd && 'prod') ||
  'dev'

const config = envConfigs[appEnv]

export default config
