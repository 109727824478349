import 'rapidoc'
import { useParams } from 'react-router-dom'

import sellerApiSpec from '../../assets/sellers-v1.yml'
import taxonomyApiSpec from '../../assets/item_taxonomies-v2.yml'
import sellerOrdersApiSpec from '../../assets/seller_orders-v1.yml'

export const Docs = () => {
  let { type, subtype } = useParams()

  if (type !== 'spec') {
    return ''
  }

  let specUrl
  switch (subtype) {
    case 'seller':
      specUrl = sellerApiSpec
      break
    case 'orders':
      specUrl = sellerOrdersApiSpec
      break
    case 'taxonomy':
      specUrl = taxonomyApiSpec
      break
    default:
      specUrl = sellerApiSpec
  }

  return (
    <rapi-doc
      spec-url={specUrl}
      show-header="false"
      render-style="focused"
      allow-spec-file-download
      allow-try="false"
    />
  )
}

export default Docs
