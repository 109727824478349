import Grid from '@mui/material/Grid'
import styled from '@emotion/styled'
import FooterV2 from '../FooterV2'
import StoryBlockV2 from '../StoryBlockV2'
import HeroBanner from '../HeroBanner'

const StyledHomeContainer = styled(Grid)({
  margin: 'auto',
  maxWidth: '1366px',
})

const StyledContentSeparator = styled(Grid)(({ theme }) => ({
  height: theme.spacing(4),
}))

const Home = () => (
  <>
    <HeroBanner />
    <StyledContentSeparator item xs={12} />
    <StyledHomeContainer container justifyContent="center" spacing={0}>
      <Grid item xs={12}>
        <StoryBlockV2 />
      </Grid>
    </StyledHomeContainer>
    <FooterV2 />
  </>
)

export default Home
