const STAGE_HOSTNAME = 'plus.perf.target.com'
const STAGE_TAP_HOSTNAME = 'sellerlandingui.dev.cnf.gcp.target.com'
const PROD_HOSTNAME = 'plus.target.com'
const PROD_TAP_HOSTNAME = 'sellerlandingui.prod.cnf.gcp.target.com'

const commonConfig = {
  imageBasePath: '//target.scene7.com/is/image/Target',
  taxonomyUrls: {
    spec: '/docs/spec/taxonomy',
  },
  sellerOrderUrls: {
    spec: '/docs/spec/orders',
  },
  homePageNodeId: 'yep2u',
  sellerUrls: {
    portal: 'https://plus-portal.perf.target.com',
    spec: '/docs/spec/seller',
  },
}

const config = {
  dev: {
    env: 'development',
    sellerUrls: {
      portal: 'http://localhost:8080',
      spec: '/docs/spec/seller',
    },
  },
  stage: {
    env: 'stage',
    sellerUrls: {
      portal: 'https://plus-portal.perf.target.com',
      spec: '/docs/spec/seller',
    },
  },
  prod: {
    env: 'production',
    sellerUrls: {
      portal: 'https://plus-portal.target.com',
      spec: '/docs/spec/seller',
    },
  },
}

const hostname = window.location.hostname

const isProd = hostname.match(
  new RegExp(`^${PROD_HOSTNAME}|${PROD_TAP_HOSTNAME}$`, 'i'),
)

const isStage =
  !isProd &&
  hostname.match(new RegExp(`^${STAGE_HOSTNAME}|${STAGE_TAP_HOSTNAME}$`, 'i'))

const appEnv =
  (isStage && 'stage') || (isProd && 'prod') || process.env.REACT_APP_ENV

const envConfig = config[appEnv]

const appConfig = {
  ...commonConfig,
  ...envConfig,
}

export default appConfig
