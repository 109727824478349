const CuratingJoySvg = () => (
  <svg
    width={297}
    height={178}
    viewBox="0 0 297 178"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_314_3003)">
      <path
        d="M148.5 61.5C76.6998 61.5 16.4998 111.2 0.299805 178H296.7C280.6 111.1 220.3 61.4 148.5 61.4V61.5Z"
        fill="#E0E0E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.7 4.90002L162.7 15.9H212.1L201.1 4.90002H173.6H173.7Z"
        fill="#720000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M162.7 16L173.7 5V36.4H162.7V16ZM201.1 5L212.1 16V36.4H201.1V5Z"
        fill="#CC0000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M132.4 178H242.2V33.4H132.4V178Z"
        fill="#CC0000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188 79C190.5 72.7 192.5 70.7 198.8 68.2C192.5 65.7 190.5 63.7 188 57.4C185.5 63.7 183.5 65.7 177.2 68.2C183.5 70.7 185.5 72.7 188 79ZM202.5 92.6C205.2 92.6 207.3 90.4 207.3 87.8C207.3 85.2 205.1 83 202.5 83C199.9 83 197.7 85.2 197.7 87.8C197.7 90.4 199.9 92.6 202.5 92.6Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.2 77.2C101.2 72.5 105 68.6 109.8 68.6H155C159.7 68.6 163.6 72.4 163.6 77.2V81.1H101.3V77.2H101.2ZM109.8 178C105.1 178 101.2 174.1 101.2 169.2V158.6H163.5V169.2C163.5 174 159.7 178 154.9 178H109.7H109.8Z"
        fill="#666666"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.2 158.7H163.5V81.2H101.2V158.7Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.7 98.1C122.2 98.1 125.9 101.8 125.9 106.3C125.9 110.8 122.2 114.5 117.7 114.5C113.2 114.5 109.5 110.8 109.5 106.3C109.5 101.8 113.2 98.1 117.7 98.1Z"
        fill="#137203"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.1 108.7L113.7 106.4L112.9 107.2L116.1 110.2L122.7 103.9L121.9 103.1L116.1 108.7Z"
        fill="white"
        stroke="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.5 132.8C123 132.8 126.7 136.5 126.7 141C126.7 145.5 123 149.2 118.5 149.2C114 149.2 110.3 145.5 110.3 141C110.3 136.5 114 132.8 118.5 132.8Z"
        fill="#CC0000"
      />
      <path
        d="M121.535 137.262L114.676 144.121L115.454 144.899L122.313 138.04L121.535 137.262Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M115.454 137.121L114.676 137.899L121.535 144.758L122.313 143.98L115.454 137.121Z"
        fill="white"
        stroke="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.2 173.8C130.2 173.8 127.7 171.3 127.7 168.3C127.7 165.3 130.2 162.8 133.2 162.8C136.2 162.8 138.7 165.3 138.7 168.3C138.7 171.3 136.2 173.8 133.2 173.8Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.7 147H143.7V132.8H135.7V147Z"
        fill="#BA9874"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.7 147H157.1V132.8H143.7V147Z"
        fill="#CEAF8C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M141.1 98.2V97.9L138.3 98.7L134.6 103.6L137.9 106.9L139 106.2L139.5 115.6L144.8 115.8V97.4L141 98.1L141.1 98.2Z"
        fill="#FA8627"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.5 105.5L138 107L139.1 106.3L138.8 102.9L136.5 105.5Z"
        fill="#F26A30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144.9 115.8H150.2L150.7 106.3L151.8 107L155.1 103.7L151.4 98.8L148.7 98V98.3L144.8 97.7V115.8H144.9Z"
        fill="#FACE0B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M141.9 98.2V96.1H147.8V98.2L144.8 102.2L141.9 98.2Z"
        fill="#E8E8E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M141.9 96.1L139.8 99.4L144.8 102.2L141.9 96.1Z"
        fill="#FACE0B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.8 96.1L144.8 102.2L149.8 99.4L147.8 96.1Z"
        fill="#FA8627"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M145.7 104C145.9 104 146.1 103.8 146.1 103.6C146.1 103.4 145.9 103.2 145.7 103.2C145.5 103.2 145.3 103.4 145.3 103.6C145.3 103.8 145.5 104 145.7 104ZM146.1 106.6C146.1 106.8 145.9 107 145.7 107C145.5 107 145.3 106.8 145.3 106.6C145.3 106.4 145.5 106.2 145.7 106.2C145.9 106.2 146.1 106.4 146.1 106.6ZM146.1 109.7C146.1 109.9 145.9 110.1 145.7 110.1C145.5 110.1 145.3 109.9 145.3 109.7C145.3 109.5 145.5 109.3 145.7 109.3C145.9 109.3 146.1 109.5 146.1 109.7Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144.1 97.6H145.6V96.7H144.1V97.6Z"
        fill="#FACE0B"
      />
      <mask
        id="mask0_314_3003"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={61}
        width={297}
        height={117}
      >
        <path
          d="M148.5 61.5C76.6998 61.5 16.4998 111.2 0.299805 178H296.7C280.6 111.1 220.3 61.4 148.5 61.4V61.5Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_314_3003)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.2 140.6C120.2 137.7 117.4 135.5 114.5 135.5H76.6C75.4 135.5 74.5 134.6 74.5 133.4C74.5 132.2 75.4 131.3 76.6 131.3H86.2C96.7 131.1 96.4 121.1 96.4 120.3H59.8C55.5 120.3 51.3 122 48.3 125.1L42 131.4C39.4 134 35.8 135.5 32.1 135.5H0.5V178.2H86.5C89.2 178.2 91.9 176 92.1 173.3C92.3 171.1 91.2 169.2 89.5 168.2C88.9 167.9 88.9 167.1 89.5 166.8C91.1 165.9 92.1 164.2 92.1 162.2C92.1 160.2 91 158.5 89.5 157.6C88 156.7 89 156.5 89.5 156.2C91.1 155.3 92.1 153.6 92.1 151.6C92.1 149.6 91 147.9 89.4 146.9C89.2 146.8 89.2 146.6 89.4 146.4H114.8C116.7 146.4 117 146 117.9 145.4C119.4 144.4 120.3 142.6 120.2 140.7V140.6Z"
          fill="#B07F63"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M78.3999 178.2H86.5999C89.2999 178.2 91.9999 176 92.1999 173.3C92.3999 171.1 91.2999 169.2 89.5999 168.2C88.9999 167.9 88.9999 167.1 89.5999 166.8C91.1999 165.9 92.1999 164.2 92.1999 162.2C92.1999 160.2 91.0999 158.5 89.5999 157.6C88.9999 157.3 88.9999 156.5 89.5999 156.2C91.1999 155.3 92.1999 153.6 92.1999 151.6C92.1999 149.6 91.0999 147.9 89.4999 146.9C89.2999 146.8 89.2999 146.6 89.4999 146.4H78.3999V178.2Z"
          fill="#9A6E55"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_314_3003">
        <rect width={297} height={178} fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default CuratingJoySvg
