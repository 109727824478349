import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'
import styled from '@emotion/styled'

import Header from '../Header'
import Home from '../Home'
import Docs from '../Docs'

import './app.css'

const StyledContainer = styled('div')({
  minWidth: '300px',
})

const theme = createTheme({
  typography: {
    fontFamily: [
      'HelveticaforTarget-Light',
      'HelveticaforTargetRegular',
      'HelveticaforTargetBold',
      'TargetHelvitica',
    ].join(','),
  },
})

export const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <StyledContainer>
            <Header />
            <Router>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/docs/:type/:subtype" element={<Docs />} />
              </Routes>
            </Router>
          </StyledContainer>
        </CssBaseline>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
