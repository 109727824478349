import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'

import styled from '@emotion/styled'

const StyledExternalPolicyLink = styled(Link)({
  color: '#CC0000',
  textDecorationColor: '#CC0000',
  cursor: 'pointer',
})

const StyledCloseButton = styled(Button)(({ theme }) => ({
  color: '#000000',
  borderColor: '#000000',
  textTransform: 'capitalize',
  '&:hover': {
    borderColor: '#000000',
  },
  padding: theme.spacing(1.25, 1.5, 1.25, 1.5),
}))

const StyledDialog = styled(Dialog)(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))

const StyledDialogTitle = styled(DialogTitle)({
  fontSize: '23px',
  fontWeight: 'bold',
  borderBottom: '1px solid rgb(214, 214, 214)',
  display: 'flex',
  alignItems: 'center',
})

const StyledDialogContentText = styled(DialogContentText)({
  color: '#000000',
})

const StyledSpacer = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}))

export const PrivacyPolicy = (props) => {
  const { isOpen, closeModal } = props

  return (
    <StyledDialog open={isOpen} onClose={closeModal} maxWidth="sm">
      <StyledDialogTitle>
        Target+ Seller Application Privacy Statement
        <StyledIconButton onClick={closeModal} aria-label="close">
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>
      <DialogContent>
        <StyledSpacer />
        <StyledDialogContentText>
          At Target, we want you to know how we collect, use, share and protect
          information about you. This privacy policy applies to information
          collected as part of Target Plus’s seller process.{' '}
        </StyledDialogContentText>
        <StyledSpacer />
        <StyledDialogContentText fontWeight="bold">
          What information is collected?
        </StyledDialogContentText>
        <StyledSpacer />
        <StyledDialogContentText>
          We collect information you provide directly, including:
        </StyledDialogContentText>
        <ul>
          <li> Name </li>
          <li>Email address</li>
          <li>Company Name</li>
          <li>
            Company Information, e.g. description of assortment, operations,
            technology, capabilities
          </li>
        </ul>
        <StyledDialogContentText>
          We may also automatically collect information about your use of the
          Target Plus Seller Application, including:
        </StyledDialogContentText>{' '}
        <ul>
          <li> Utilization data, e.g. # of users </li>
          <li>
            Session data, e.g. date and time device entered, length of session
          </li>
          <li>Type of device, e.g. iPhone, Samsung tablet</li>
          <li>Browser data, e.g. browser version, IP address</li>
          <li>Device data, e.g. Device ID number, MAC address</li>
        </ul>
        <StyledDialogContentText fontWeight="bold">
          How is this information used?
        </StyledDialogContentText>
        <StyledSpacer />
        <StyledDialogContentText>
          Ways we use the information we collect include:
        </StyledDialogContentText>
        <ul>
          <li>
            Responding to applications, e.g. sending information about Target
            Plus
          </li>
          <li>Internal operations, e.g. opportunities by category</li>
          <li>
            Legal compliance, e.g. assist law enforcement and respond to
            legal/regulatory inquiries
          </li>
        </ul>
        <StyledDialogContentText fontWeight="bold">
          How is this information shared?
        </StyledDialogContentText>
        <StyledSpacer />
        <StyledDialogContentText>
          The information we collect may be shared:
        </StyledDialogContentText>
        <ul>
          <li>
            Within Target Corporation, which includes all Target subsidiaries
            and affiliates
          </li>
          <li>
            When we believe disclosure is appropriate to comply with the law; to
            apply applicable terms and conditions and other agreements; or to
            protect the rights, property or safety of our company, our guests or
            others
          </li>
        </ul>
        <StyledDialogContentText fontWeight="bold">
          Other web site analytics services
        </StyledDialogContentText>
        <StyledSpacer />
        <StyledDialogContentText>
          Analytics services such as Google Analytics provide services analyze
          information regarding visits to our websites and mobile applications.
          They use cookies, web beacons, and other tracking mechanisms to
          collect this information.
        </StyledDialogContentText>
        <ul>
          <li>
            To learn more about Google’s privacy practices, click{' '}
            <StyledExternalPolicyLink
              underline="none"
              href="https://policies.google.com/privacy"
              target="_blank"
              aria-label="Click here to view Google's privacy practices"
            >
              here
            </StyledExternalPolicyLink>
            . To access and use the Google Analytics Opt-out Browser Add-on,
            click{' '}
            <StyledExternalPolicyLink
              underline="none"
              href="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
              aria-label="Click here to view Google Analytics Opt-out Browser Add-on"
            >
              here
            </StyledExternalPolicyLink>
            .
          </li>
        </ul>
        <StyledDialogContentText>
          <b>How is this information protected?</b>
        </StyledDialogContentText>
        <StyledSpacer />
        <StyledDialogContentText>
          We maintain administrative, technical and physical safeguards to
          protect your information.
        </StyledDialogContentText>
        <StyledSpacer />
        <StyledDialogContentText>
          California Residents: For state-specific information, please see the
          California Business Privacy Notice at{' '}
          <StyledExternalPolicyLink
            underline="none"
            href="https://corporate.target.com/about/contact-help#privacy"
            target="_blank"
            aria-label="Click here to view California Business Privacy Notice"
          >
            https://corporate.target.com/about/contact-help#privacy
          </StyledExternalPolicyLink>
          .
        </StyledDialogContentText>
        <StyledSpacer />
        <StyledDialogContentText>
          <b>How do you contact Target?</b>
        </StyledDialogContentText>
        <ul>
          <li>
            Mail: Target HQ Locations, Target Executive Offices, P.O. Box 9350,
            Minneapolis, MN 55440
          </li>
          <li>Phone: 800-440-0680</li>
        </ul>
      </DialogContent>
      <DialogActions>
        <StyledCloseButton
          variant="outlined"
          fullWidth
          size="large"
          onClick={closeModal}
          aria-label="close"
        >
          Close
        </StyledCloseButton>
      </DialogActions>
    </StyledDialog>
  )
}

export default PrivacyPolicy
