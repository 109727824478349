import Firefly from 'firefly-sdk-ts'

import { eventManagerConfig } from '../../../appConfig/fireflyConfig'

let globalData = {}
let loadTimestamp

export const resetGlobalData = () => {
  globalData = {}
  loadTimestamp = undefined
}

export const initializeFireFly = () => {
  Firefly.registerEventManagerWithConfig(eventManagerConfig)
  globalData.application = {
    name: 'Target Plus Landing Page',
  }
}

export const trackPageView = () => {
  Firefly.getEventManager(eventManagerConfig).addEvent({
    event: {
      type: 'pageload',
    },
  })
}

export const setPageData = (pageTitle) => {
  globalData.view = {
    name: pageTitle,
  }

  const now = new Date().getTime()

  // push previous loadTimestamp and update
  globalData.customMetrics = {
    metric1: loadTimestamp ? ((now - loadTimestamp) / 1000).toString() : '',
  }
  loadTimestamp = now

  // clear out referrers, as they may contain access tokens
  if (globalData.browser) {
    Object.defineProperty(globalData.browser, 'referrer', {
      get: function () {
        return ''
      },
    })
  }

  if (globalData.appState) {
    Object.defineProperty(globalData.appState, 'referrer', {
      get: function () {
        return ''
      },
    })
  }

  Firefly.setGlobalDataLayer(globalData, eventManagerConfig.persistMethod)
  trackPageView()
}

export function trackCustomEvent(eventType, key, value, location, metrics) {
  const data = {
    key,
    value: value.toString(),
  }

  if (location) {
    data.location = location
  }

  let customEvent = {
    event: { type: eventType },
    customInteraction: data,
  }

  const metricsMap = new Map()

  metrics?.map((metric, index) =>
    metricsMap.set('metric' + (index + 1), metric),
  )

  if (metrics) {
    customEvent = {
      ...customEvent,
      customMetrics: {
        metric1: metricsMap.get('metric1'),
        metric2: metricsMap.get('metric2'),
        metric3: metricsMap.get('metric3'),
      },
    }
  }

  Firefly.getEventManager(eventManagerConfig).addEvent(customEvent)
}
