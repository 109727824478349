import { AppBar, Toolbar, Typography } from '@mui/material'
import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import FooterLinks from './FooterLinks'

const StyledContainer = styled(Grid)(({ theme }) => ({
  margin: 'auto',
  maxWidth: '1366px',
  [theme.breakpoints.down('md')]: {
    lineHeight: 2.2,
    padding: '12px',
  },
}))

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#C00',
  color: 'white',
}))

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  color: 'white',
  flexGrow: 1,
}))

export const FooterV2 = () => {
  return (
    <StyledAppBar component="footer" position="static">
      <StyledRoot>
        <StyledContainer container>
          <Grid item xs={12} sm={6}>
            <Grid
              container
              sx={{ justifyContent: { sm: 'left', xs: 'center' } }}
            >
              <FooterLinks />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid
              container
              sx={{ justifyContent: { sm: 'right', xs: 'center' } }}
            >
              <Typography>TM & &copy;2024 Target Brands, Inc.</Typography>
            </Grid>
          </Grid>
        </StyledContainer>
      </StyledRoot>
    </StyledAppBar>
  )
}

export default FooterV2
