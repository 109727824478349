import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'

import { trackCustomEvent } from '../../services/utilities/Firefly/fireflyInsights'
import heroBanner from '../assets/hero_banner_design.png'

const StyledHeroBannerContainer = styled(Grid)(({ theme }) => ({
  background: '#E7DACD',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}))

const StyledMobileBreak = styled('br')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'inline',
  },
}))

const StyledDesktopBreak = styled('br')(({ theme }) => ({
  display: 'inline',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const StyledContainer = styled(Grid)({
  margin: 'auto',
  maxWidth: '1366px',
})

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#CC0000',
  marginBottom: 20,
  textDecoration: 'none',
  width: '201px',
  borderRadius: '32px',
  height: '50px',
  fontSize: '24px',
  textTransform: 'none',
  fontWeight: 400,
  padding: theme.spacing(1.25, 1.25, 1.25, 1.25),
  '&:hover': {
    textDecoration: 'underline',
    backgroundColor: '#CC0000',
  },
  '&:focus': {
    textDecoration: 'none',
  },
}))

const StyledImage = styled('img')({
  verticalAlign: 'bottom',
  paddingTop: '12px',
})

const StyledHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 'lighter',
  paddingTop: '12px',
  lineHeight: 1.1,
  fontSize: 48,
  [theme.breakpoints.down('sm')]: {
    fontWeight: 'lighter',
    fontSize: 36,
  },
}))

const StyledContent = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  fontSize: 24,
  lineHeight: 1.2,
  marginBottom: 24,
  [theme.breakpoints.down('sm')]: {
    fontSize: 24,
    textAlign: 'center',
  },
}))

const HeroBanner = () => {
  const handlePartnerButtonClick = () => {
    trackCustomEvent(
      'BECOME_PARTNER_FORM_BUTTON',
      'event',
      'click',
      'Partner Form',
    )
  }

  return (
    <StyledHeroBannerContainer>
      <StyledContainer container spacing={0} alignItems="center">
        <Grid item xs={12} md={6}>
          <StyledImage
            data-testid="main-image"
            src={heroBanner}
            width="100%"
            alt=""
          />
        </Grid>
        <Grid item xs={12} md={6} padding={2}>
          <Grid
            container
            sx={{ justifyContent: { xs: 'center', sm: 'normal' } }}
          >
            <StyledHeading variant="h1" gutterBottom>
              Build your brand <br /> with Target Plus
            </StyledHeading>
            <StyledContent variant="p" gutterBottom>
              Grow with us as we curate
              <StyledMobileBreak /> something joyful,
              <StyledDesktopBreak /> through <StyledMobileBreak />
              your brand and ours.
            </StyledContent>
            <StyledButton
              disableElevation
              size="large"
              variant="contained"
              href="https://targetteam.tfaforms.net/3"
              data-testid="become-partner-link"
              onClick={handlePartnerButtonClick}
              target="_blank"
              aria-label="Reach out now opens in a new Tab"
            >
              Reach out now
            </StyledButton>
          </Grid>
        </Grid>
      </StyledContainer>
    </StyledHeroBannerContainer>
  )
}

export default HeroBanner
