import styled from '@emotion/styled'

const StyledSvg = styled('svg')(({ theme }) => ({
  height: 40,
  [theme.breakpoints.down('sm')]: {
    height: 40,
  },
}))

const TargetPlus = (props) => (
  <StyledSvg
    viewBox="0 0 101 60"
    fill="none"
    xmlns="http://www.w3.org/2000/StyledSvg"
    {...props}
  >
    <g clipPath="url(#clip0_182_776)">
      <path
        d="M59.7682 30C59.7682 46.4375 46.4656 59.75 30.0403 59.75C13.6151 59.75 0.25 46.4375 0.25 30C0.25 13.5625 13.5526 0.25 29.9779 0.25C46.4032 0.25 59.7058 13.5625 59.7058 30M29.9779 10.1875C19.0485 10.1875 10.1177 19.0625 10.1177 30.0625C10.1177 41.0625 18.9861 49.9375 29.9779 49.9375C40.9697 49.9375 49.8381 41.0625 49.8381 30.0625C49.8381 19.0625 40.9697 10.1875 29.9779 10.1875Z"
        fill="white"
      />
      <path
        d="M29.978 20.0625C24.4821 20.0625 20.0479 24.5 20.0479 30C20.0479 35.5 24.4821 39.9375 29.978 39.9375C35.4739 39.9375 39.9081 35.5 39.9081 30C39.9081 24.5 35.4739 20.0625 29.978 20.0625Z"
        fill="white"
      />
      <path
        d="M87.497 26V12.875H79.378V26H66.3252V34.0625H79.378V47.125H87.497V34.0625H100.55V26H87.497Z"
        fill="#262325"
      />
      <path
        d="M96.6157 45.125H95.9287V44.8125H97.8023V45.125H97.0529V47.125H96.6157V45.125Z"
        fill="#262325"
      />
      <path
        d="M98.0518 44.8125H98.6138L99.2384 46.625L99.8629 44.8125H100.425V47.125H100.05V45.3125L99.4257 47.125H99.051L98.4265 45.3125V47.125H98.0518V44.8125Z"
        fill="#262325"
      />
    </g>
    <defs>
      <clipPath id="clip0_182_776">
        <rect width="100.8" height="60" fill="white" />
      </clipPath>
    </defs>
  </StyledSvg>
)

export default TargetPlus
