import Grid from '@mui/material/Grid'
import { StyledHeading, StyledStoryCard, StyledDescription } from './styles'

export const StoryCard = ({ heading, description, ImageComponent }) => {
  return (
    <StyledStoryCard>
      <Grid container justifyContent="center" spacing={0}>
        <Grid item sm={12}>
          <StyledHeading variant="h3">{heading}</StyledHeading>
          <StyledDescription fontSize={18} variant="body1">
            {description}
          </StyledDescription>
        </Grid>
        <Grid item sm={12} display="flex">
          <Grid container alignItems="center" justifyContent="center">
            <Grid item display="flex">
              <ImageComponent />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledStoryCard>
  )
}

export default StoryCard
