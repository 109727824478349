const HelpingYouGrowSvg = () => (
  <svg
    width={297}
    height={178}
    viewBox="0 0 297 178"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_314_2980)">
      <path
        d="M148.5 61.5C76.6998 61.5 16.4998 111.2 0.299805 178H296.7C280.6 111.1 220.3 61.4 148.5 61.4V61.5Z"
        fill="#E0E0E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M156.8 172.6L165.6 124.1H109.4L104.1 150.9C101.3 164.9 112.1 178 126.4 178H188.9V172.5H156.7L156.8 172.6Z"
        fill="#AAAAAA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M181.4 169.2H127C117.7 169.2 110.6 160.7 112.4 151.5L119.5 115.3H175.7L168.9 150.7C167.2 159.4 173.1 167.6 181.5 169.2H181.4Z"
        fill="#878787"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206.7 178H149.5V169.2H197.8C202.7 169.2 206.6 173.1 206.6 178H206.7Z"
        fill="#CCCCCC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159 178H134.4V169.2H150.2C155.1 169.2 159 173.1 159 178Z"
        fill="#AAAAAA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.8999 24.1C54.8999 12.9 63.9999 3.90002 75.0999 3.90002H219.2V124.6H75.0999C63.8999 124.6 54.8999 115.5 54.8999 104.4V24.1Z"
        fill="#AAAAAA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.8999 24.1C63.8999 12.9 72.9999 3.90002 84.0999 3.90002H217.5C228.7 3.90002 237.7 13 237.7 24.1V104.3C237.7 115.5 228.6 124.5 217.5 124.5H84.0999C72.8999 124.5 63.8999 115.4 63.8999 104.3V24.1Z"
        fill="#666666"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.8999 112.6H225.8V15.9H75.8999V112.6Z"
        fill="white"
      />
      <path
        d="M192.499 81C200.839 81 207.599 74.2843 207.599 66C207.599 57.7157 200.839 51 192.499 51C184.16 51 177.399 57.7157 177.399 66C177.399 74.2843 184.16 81 192.499 81Z"
        fill="white"
      />
      <path
        d="M193.7 85.9H191.8C190.6 85.9 189.4 85.7 188.2 85.4C183.6 84.3 179.8 81.8 177 78C173.5 73.3 172.4 68 173.6 62.3C174.6 57.4 177.3 53.5 181.3 50.6C184.3 48.4 187.6 47.2 191.2 46.9C193.7 46.7 196.2 46.9 198.7 47.7C203.6 49.2 207.3 52.2 209.9 56.6C211.3 59 212.2 61.6 212.5 64.3C212.5 64.7 212.5 65.1 212.6 65.5V67.6C212.5 68.5 212.4 69.5 212.2 70.4C210.8 76.5 207.4 81 201.9 83.9C199.8 85 197.5 85.7 195 85.9C194.6 85.9 194.2 85.9 193.7 85.9Z"
        fill="#136308"
      />
      <path
        d="M192.1 75.6C188.3 75.3 186.2 73.2 185.8 69.3H189.4C189.5 71.4 190.4 72.4 192.1 72.6V67.5C188.8 66.7 186.2 65.7 186.2 62.1C186.2 58.5 188.6 57 192.1 56.7V54.8H193.6V56.7C197 57.1 199.1 58.9 199.3 62H195.7C195.7 60.7 194.8 59.6 193.6 59.6V64.1C198.3 65.1 199.9 66.7 199.9 70C199.9 73.3 198.2 75.2 193.6 75.5V77.6H192.1V75.5V75.6ZM192.1 59.8C190.8 59.8 189.8 60.6 189.8 61.9C189.8 63.2 190.5 63.5 192.1 63.9V59.8ZM196.3 70.2C196.3 69.1 195.6 68.5 193.6 68V72.7C195.2 72.7 196.3 71.6 196.3 70.2Z"
        fill="white"
      />
      <path
        d="M95.9995 79.3V108.7H111.6V70.9C106.5 73.8 101.3 76.7 95.9995 79.2V79.3Z"
        fill="#CA2127"
      />
      <path
        d="M116.5 68.2V108.8H132.1V58.7C126.9 61.9 121.7 65.1 116.5 68.2Z"
        fill="#AA131F"
      />
      <path
        d="M136.899 55.6V108.7H152.499V45.9C147.299 49.1 142.099 52.3 136.899 55.6Z"
        fill="#680404"
      />
      <path
        d="M91.1997 72L156 36.1L157.2 38.2L158.8 32.8L153.3 31.2L154.5 33.3L89.6997 69.2L91.1997 72Z"
        fill="#D22020"
      />
    </g>
    <defs>
      <clipPath id="clip0_314_2980">
        <rect width={297} height={178} fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default HelpingYouGrowSvg
