import 'babel-polyfill'
import { createRoot } from 'react-dom/client'

import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'

import App from './components/App'

import {
  initializeFireFly,
  setPageData,
} from './services/utilities/Firefly/fireflyInsights'

initializeFireFly()
setPageData('Landing Page Home')

const theme = createTheme()

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </StyledEngineProvider>,
)
