const ProvidingSupportSvg = () => (
  <svg
    width={297}
    height={178}
    viewBox="0 0 297 178"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M148.5 61.4C76.6998 61.4 16.4998 111.1 0.299805 177.9H296.7C280.6 111 220.3 61.3 148.5 61.3V61.4Z"
      fill="#E0E0E0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M164.299 176.2V153.6L188.499 178L211.299 159L171.099 118.4C160.599 107.8 146.099 101.2 129.999 101.2C113.899 101.2 99.3994 107.8 88.8994 118.4C78.3994 129 71.8994 143.7 71.8994 159.9V176.2H164.299Z"
      fill="#CC0000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.599 97.6C138.899 97.6 138.899 97.2 138.599 97.2C138.299 97.2 138.299 97.6 138.599 97.6Z"
      fill="#B5865E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.399 121.2C129.699 121.2 120.999 129.9 120.999 140.6C120.999 151.3 129.699 160 140.399 160C151.099 160 159.799 151.3 159.799 140.6C159.799 129.9 151.099 121.2 140.399 121.2ZM140.399 153.5C133.299 153.5 127.499 147.7 127.499 140.6C127.499 133.5 133.299 127.7 140.399 127.7C147.499 127.7 153.299 133.5 153.299 140.6C153.299 147.7 147.499 153.5 140.399 153.5ZM140.399 147C143.999 147 146.899 144.1 146.899 140.5C146.899 136.9 143.999 134 140.399 134C136.799 134 133.899 136.9 133.899 140.5C133.899 144.1 136.799 147 140.399 147Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M262.299 126.1L216.099 172.3H132.499L178.699 126.1H262.299ZM216.099 177.9H57.8994V172.3H216.099V177.9Z"
      fill="#AAAAAA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.499 177.9H216.099V172.3H132.499V177.9Z"
      fill="#666666"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.499 49.9C91.499 27.6 109.599 9.5 131.899 9.5C154.199 9.5 172.299 27.6 172.299 49.9C172.299 72.2 154.199 90.3 131.899 90.3C109.599 90.3 91.499 72.2 91.499 49.9Z"
      fill="#77564A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.899 37.5C109.099 17.1 128.099 3.20002 148.499 6.30002C168.899 9.50002 182.799 28.5 179.699 48.9C176.499 69.3 157.499 83.2 137.099 80.1C116.699 76.9 102.799 57.9 105.899 37.5Z"
      fill="#5B3425"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M114.099 45.7C112.399 46.6 112.499 47.3 112.499 48.1C112.499 48.9 112.499 49.9 110.799 51.1C105.199 51.6 100.899 56.3 100.899 62.1C100.899 67.9 105.099 72.4 110.599 73V105.6C110.899 105.9 115.999 111.1 130.199 111.1C144.399 111.1 149.499 105.9 149.799 105.6V71.3L155.399 69.7L149.699 46.4L114.299 45.6H114.099V45.7Z"
      fill="#EAC7AD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.099 55.8C113.399 57.5 134.299 56.9 150.399 49.9L155.199 69.8L149.599 71.4V96.7C175.999 90.2 177.599 49.3 165.299 29.8C165.499 28.7 165.799 27.6 166.099 26.6C158.199 13.7 151.399 6.40004 138.799 6.50004H134.299L107.699 25.3L100.099 55.9V55.8ZM149.399 105.7L149.599 105.5V96.6H149.199C148.999 96.6 148.699 96.6 148.499 96.8H141.999C138.699 96.8 135.399 96.5 132.199 95.6L142.599 108.6H142.799L143.199 108.4C143.799 108.2 144.299 108 144.699 107.8H144.999C145.099 107.6 145.199 107.6 145.299 107.5H145.599C145.599 107.3 145.699 107.3 145.799 107.3H146.099L146.399 107H146.699C146.699 106.9 146.699 106.8 146.799 106.8H147.099L147.299 106.5H147.499L147.699 106.2H147.899L148.199 105.9H148.399L148.599 105.6L148.799 105.4H148.999L149.199 105.1H149.299V105.7H149.399Z"
      fill="#DBAF91"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.899 77.3C140.899 87 157.399 86.9 159.299 77.3H138.899Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M157.699 62.4C157.699 62.9 157.699 63.4 157.999 63.8C158.199 63.8 158.399 63.6 158.599 63.5C159.499 63.1 160.599 62.9 161.699 62.9C162.799 62.9 163.799 63.1 164.799 63.5C164.999 63.5 165.199 63.7 165.399 63.8C165.599 63.4 165.699 62.9 165.699 62.4C165.699 60.2 163.899 58.4 161.699 58.4C159.499 58.4 157.699 60.2 157.699 62.4ZM135.199 62.4C135.199 62.9 135.199 63.4 135.499 63.8C135.699 63.8 135.899 63.6 136.099 63.5C136.999 63.1 138.099 62.9 139.199 62.9C140.299 62.9 141.299 63.1 142.299 63.5C142.499 63.5 142.699 63.7 142.899 63.8C143.099 63.4 143.199 62.9 143.199 62.4C143.199 60.2 141.399 58.4 139.199 58.4C136.999 58.4 135.199 60.2 135.199 62.4Z"
      fill="#2F2F2F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M177.199 28.7C175.099 23.3 161.399 -3.29998 122.399 5.50002C112.999 7.60002 111.399 14.5 104.999 22.4C98.199 30.9 93.799 57.5 100.999 57.3C119.199 56.8 150.699 52.3 177.199 28.6V28.7Z"
      fill="#77564A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M240.9 43.2H172.4V84H159.8L172.4 96.6H240.9V43.1V43.2Z"
      fill="#C9F0FC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M186.399 60.3H223.499V55.8H186.399V60.3ZM186.399 84.1H220.099V79.6H186.399V84.1ZM226.999 72.2H186.399V67.7H226.999V72.2Z"
      fill="#666666"
    />
  </svg>
)

export default ProvidingSupportSvg
