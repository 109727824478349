import { useState } from 'react'
import Link from '@mui/material/Link'
import PrivacyPolicy from './PrivacyPolicy'
import styled from '@emotion/styled'

const StyledDivider = styled('span')({
  marginLeft: 10,
  marginRight: 10,
})

const StyledLink = styled(Link)({
  '&:hover': {
    textDecoration: 'underline',
  },
})

export const FooterLinks = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <div>
      <StyledLink
        sx={{ cursor: 'pointer' }}
        onClick={handleClick}
        underline="none"
        color="white"
        href="#"
      >
        Privacy Policy
      </StyledLink>
      <StyledDivider>|</StyledDivider>
      <StyledLink
        href="https://target.com"
        underline="none"
        color="white"
        target="_blank"
        aria-label="Target.com opens in a new Tab"
      >
        Target.com
      </StyledLink>
      {isOpen && <PrivacyPolicy isOpen={isOpen} closeModal={closeModal} />}
    </div>
  )
}

export default FooterLinks
