import { ExpandMore, Menu as MenuIcon } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Grid,
} from '@mui/material'
import { Component } from 'react'

import styled from '@emotion/styled'

import appConfig from '../../appConfig'
import TargetPlus from '../common/Icons/TargetPlus'

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#C00',
  height: 70,
  [theme.breakpoints.down('sm')]: {
    height: 70,
  },
}))

const StyledDesktopSection = styled('div')(({ theme }) => ({
  display: 'none',
  marginLeft: theme.spacing(3.5),
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
}))

const StyledMobileSection = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}))

const StyledHeaderContainer = styled(Grid)({
  margin: 'auto',
  maxWidth: '1366px',
})

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  color: 'white',
  flexGrow: 1,
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
}))

const StyledDisableHoverButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontSize: 20,
  '&:hover': {
    backgroundColor: 'rgb(0, 0, 0, 0)',
    textDecoration: 'underline',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
  },
}))

const StyledSignInButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  cursor: 'pointer',
  borderStyle: 'none',
  color: '#000',
  background: '#F2EBE3',
  marginLeft: '10px',
  boxShadow: 'none',
  width: '100px',
  borderRadius: '24px',
  height: '40px',
  fontSize: '20px',
  '&:hover': {
    background: '#F2EBE3',
    boxShadow: 'none',
    textDecoration: 'underline',
  },
  '&:focus': {
    textDecoration: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    height: '40px',
    width: '100px',
  },
}))

const StyledDisableHoverIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  '&:hover': {
    backgroundColor: 'rgb(0, 0, 0, 0)',
  },
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
}))

const StyledGrowDiv = styled('div')({
  flexGrow: 1,
})

export class Header extends Component {
  state = {
    desktopMenuAnchor: null,
    mobileMenuAnchor: null,
  }

  handleDesktopMenuClose = () => {
    this.setState({ desktopMenuAnchor: null })
  }

  handleDesktopMenuOpen = (event) => {
    this.setState({ desktopMenuAnchor: event.currentTarget })
  }

  handleMobileMenuClose = () => {
    this.setState({ mobileMenuAnchor: null })
  }

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMenuAnchor: event.currentTarget })
  }

  renderMenuLink = (href, text) => (
    <Link href={href} underline="none" color="inherit">
      <MenuItem>{text}</MenuItem>
    </Link>
  )

  renderResourceMenuItems = () => {
    return (
      <>
        <Accordion disableGutters elevation={0}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            Domain APIs
          </AccordionSummary>
          <AccordionDetails>
            {this.renderMenuLink(appConfig.sellerUrls.spec, 'sellers-v1')}
            {this.renderMenuLink(
              appConfig.sellerOrderUrls.spec,
              'seller_orders-v1',
            )}
          </AccordionDetails>
        </Accordion>
        {this.renderMenuLink(appConfig.taxonomyUrls.spec, 'Item Taxonomy API')}
      </>
    )
  }

  renderDesktopMenus = () => {
    const { desktopMenuAnchor } = this.state

    const isSpecMenuOpen = Boolean(desktopMenuAnchor)

    return (
      <StyledDesktopSection>
        <StyledDisableHoverButton
          id="desktopMenu"
          aria-haspopup="true"
          color="inherit"
          onClick={this.handleDesktopMenuOpen}
        >
          Resources
          <ExpandMore />
        </StyledDisableHoverButton>
        <Menu
          id="desktopMenu"
          anchorEl={desktopMenuAnchor}
          open={isSpecMenuOpen}
          onClose={this.handleDesktopMenuClose}
        >
          {this.renderResourceMenuItems()}
        </Menu>
      </StyledDesktopSection>
    )
  }

  renderMobileMenu = () => {
    const { mobileMenuAnchor } = this.state
    const isMenuOpen = Boolean(mobileMenuAnchor)

    return (
      <Menu
        anchorEl={mobileMenuAnchor}
        open={isMenuOpen}
        onClose={this.handleMobileMenuClose}
      >
        {this.renderMenuLink(appConfig.sellerUrls.portal, 'Sign in')}
        {this.renderResourceMenuItems()}
      </Menu>
    )
  }

  render() {
    const desktopMenus = this.renderDesktopMenus()
    const mobileMenu = this.renderMobileMenu()

    return (
      <StyledAppBar data-testid="header" position="sticky">
        <StyledHeaderContainer container justifyContent="center" spacing={0}>
          <StyledRoot disableGutters>
            <StyledDisableHoverIconButton
              href="/"
              size="large"
              aria-label="target plus home"
            >
              <TargetPlus fill="#FFF" />
            </StyledDisableHoverIconButton>
            <StyledGrowDiv />
            {desktopMenus}
            <StyledDesktopSection>
              <StyledSignInButton
                variant="contained"
                href={appConfig.sellerUrls.portal}
              >
                Sign in
              </StyledSignInButton>
            </StyledDesktopSection>
            <StyledMobileSection>
              <IconButton
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit"
                size="large"
              >
                <MenuIcon sx={{ width: 32, height: 32 }} />
              </IconButton>
            </StyledMobileSection>
          </StyledRoot>
          {mobileMenu}
        </StyledHeaderContainer>
      </StyledAppBar>
    )
  }
}

export default Header
