import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import styled from '@emotion/styled'

export const StyledText = styled(Typography)({
  textAlign: 'center',
  lineHeight: 1.0,
  fontSize: '48px',
  fontWeight: 'lighter',
  marginTop: 0,
  letterSpacing: 0,
  color: '#CC0000',
  marginBottom: '34px',
})

export const StyledContentSeparator = styled(Grid)(({ theme }) => ({
  height: theme.spacing(6),
}))

export const StyledBodyContainer = styled(Grid)(({ theme }) => ({
  padding: '-25px 25px',
  [theme.breakpoints.down('sm')]: {
    padding: '0 15px',
  },
}))

export const StyledHeading = styled(Typography)(({ theme }) => ({
  fontSize: '30px',
  fontFamily: 'HelveticaforTargetBold',
  textAlign: 'center',
  color: '#333333',
  paddingBottom: '16px',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    display: 'flex',
    fontSize: '32px',
  },
}))

export const StyledDescription = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  paddingBottom: '18px',
  color: '#000000',
  lineHeight: 1.2,
  textAlign: 'center',
  minHeight: 90,
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex',
    paddingBottom: '12px',
    minHeight: 'inherit',
  },
}))

export const StyledImage = styled.img(({ theme }) => ({
  paddingTop: '14px',
  marginBottom: '-9px',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    display: 'flex',
  },
}))

export const StyledStoryCard = styled.div(({ theme }) => ({
  background: '#F2EBE3',
  borderRadius: '16px',
  display: 'flex',
  height: '100%',
  width: '100%',
  overflow: 'clip',
  padding: '25px 25px 0 25px',
  [theme.breakpoints.down('sm')]: {
    padding: '15px -5px 15px 15px',
  },
}))
